<!-- eslint-disable vue/no-v-html -->
<template>
	<v-snackbar v-model="snackbar.active" :color="snackbar.color" :timeout="snackbar.timeout">
		<v-flex justify-center align-center v-html="snackbar.text" />
		<v-btn v-if="snackbar.cancel" dark flat @click="snackbar.cancel">{{ $t('actions.cancel') }}</v-btn>
		<v-btn v-if="snackbar.callback" dark flat @click="snackbar.callback">{{ $t(snackbar.callbackText) }}</v-btn>
	</v-snackbar>
</template>

<script>
import AppModuleGuard from '@/mixins/ModulesGuards/AppModuleGuard'

export default {
	name: 'SnackBar',
	mixins: [AppModuleGuard],
	data: function () {
		return {
			snackbar: {
				active: false,
				color: null,
				text: null,
				cancel: null,
				callback: null,
				callbackText: null
			},
			presetSuccess: {
				color: 'success',
				timeout: 3000
			},
			presetInfo: {
				color: 'info',
				timeout: 3000
			},
			presetWarning: {
				color: 'warning',
				timeout: 5000
			},
			presetError: {
				color: 'error',
				timeout: 7000
			},
			isDisplayingError: false
		}
	},
	methods: {
		getAppEventsActionsMapping: function () {
			return [
				{ event: this.appEvents.SNACKBAR_SUCCESS, action: this.snackBarSuccess },
				{ event: this.appEvents.SNACKBAR_INFO, action: this.snackBarInfo },
				{ event: this.appEvents.SNACKBAR_WARNING, action: this.snackBarWarning },
				{ event: this.appEvents.SNACKBAR_ERROR, action: this.snackBarError },
				{ event: this.appEvents.SNACKBAR_RESET, action: this.snackBarReset }
			]
		},
		snackBarSuccess: function (snackData) {
			this.setSnackBar(snackData, this.presetSuccess)
		},
		snackBarInfo: function (snackData) {
			this.setSnackBar(snackData, this.presetInfo)
		},
		snackBarWarning: function (snackData) {
			this.setSnackBar(snackData, this.presetWarning)
		},
		snackBarError: function (snackData) {
			this.setSnackBar(snackData, this.presetError)
		},
		setSnackBar: function (snackData, preset) {
			if (snackData) {
				let snackDataSet = Object.assign({}, preset, this.getSnackDataSet(snackData))
				this.snackbar.text = snackDataSet.text
				this.snackbar.color = snackDataSet.color
				this.snackbar.timeout = snackDataSet.timeout
				this.snackbar.cancel = snackDataSet.cancel
				this.snackbar.callback = snackDataSet.callback
				this.snackbar.callbackText = snackDataSet.callbackText
				this.snackbar.active = true
			}
		},
		getSnackDataSet: function (snackData) {
			let snackDataSet = {}
			if (snackData) {
				switch (snackData.constructor) {
					case Error:
						snackDataSet = this.getSnackDataSetFromError(snackData)
						break
					case Object:
						snackDataSet = this.getSnackDataSetFromObject(snackData)
						break
					default:
						snackDataSet = this.getSnackDataSetFromString(snackData)
						break
				}
			}
			return snackDataSet
		},
		getSnackDataSetFromError: function (snackData) {
			let snackDataSet = {
				text: snackData.message
			}
			if (this.$te(snackData.message)) {
				snackDataSet.text = this.$t(snackData.message)
			}
			return snackDataSet
		},
		getSnackDataSetFromObject: function (snackData) {
			let snackDataSet = {
				text: snackData.text,
				callbackText: snackData.callbackText,
				callback: snackData.callback
			}
			if (this.$te(snackData.text)) {
				snackDataSet.text = this.$t(snackData.text)
			}
			if (this.$te(snackData.callbackText)) {
				snackDataSet.callbackText = this.$t(snackData.callbackText)
			}
			if (snackData.timeout) {
				snackDataSet.timeout = snackData.timeout
			}
			return snackDataSet
		},
		getSnackDataSetFromString: function (snackData) {
			let snackDataSet = {
				text: '<center>' + snackData + '</center>'
			}
			if (this.$te(snackData)) {
				snackDataSet.text = '<center>' + this.$t(snackData) + '</center>'
			}
			return snackDataSet
		},
		snackBarReset: function () {
			this.snackbar.active = false
		}
	}
}
</script>
